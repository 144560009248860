import { MutableRefObject, useContext, useMemo, useRef, useState } from "react";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import useWindowDimensions from "hooks/useWindowDimension";

export const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const parentDealership = useMemo(() => user?.data.dealership?.id, [user]);
  const { dealershipID } = useDealership();
  const ref = useRef<HTMLIFrameElement | null>(
    null
  ) as MutableRefObject<HTMLIFrameElement>;
  const { height, width } = useWindowDimensions();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const reportURL = process.env.REACT_APP_DEALERSHIP_REPORT_URL;

  const selectedDealership = dealershipID?.includes(",")
    ? parentDealership
    : dealershipID?.split(",")[0];

  if (isError)
    return (
      <div className="ml-88 mt-52 text-base w-full text-label">
        There was an issue loading the page. Please try again.
      </div>
    );

  return (
    <>
      {isLoading && (
        <div className="p-20">
          <LoadingSpinner size={30} />
        </div>
      )}
      <iframe
        title="dashboard"
        ref={ref}
        width={width}
        height={height}
        src={new URL(
          (reportURL || "").replaceAll("@dealership", selectedDealership || "")
        ).toString()}
        className="border-0"
        allowFullScreen
        loading="lazy"
        onLoad={() => setIsLoading(false)}
        onError={() => setIsError(true)}
      />
    </>
  );
};
